import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/app/[locale]/not-found.css");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/app/[locale]/globals.css");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/app/theme.ts");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/components/shared/CookieConsent.tsx");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/components/shared/CursorFollower.tsx");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/components/shared/Image.tsx");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/components/shared/index.ts");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/components/shared/LanguageSwitcher.tsx");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/components/shared/LocationInformationSection.tsx");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/components/shared/MobileNavDropdown.tsx");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/components/shared/modals/PackagesModal.tsx");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/components/shared/modals/QuoteFormModal.tsx");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/components/shared/NavServicesDropdown.tsx");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/lib/context/bugsnag-context.tsx");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/lib/helpers/forms/QuoteFormHelper.ts");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/lib/hooks/use-animations.tsx");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/node_modules/@mui/material/Container/index.js");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/node_modules/@mui/material/CssBaseline/index.js");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/node_modules/@mui/material/List/index.js");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/node_modules/@mui/material/ListItem/index.js");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/node_modules/@mui/material/ListItemText/index.js");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/node_modules/@mui/material/styles/index.js");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/node_modules/@mui/material/Typography/index.js");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/fonts/index.ts\",\"import\":\"Blinker\",\"arguments\":[{\"variable\":\"--font-blinker\",\"weight\":[\"100\",\"200\",\"300\",\"400\",\"600\",\"700\",\"800\"],\"subsets\":[\"latin\"]}],\"variableName\":\"blinker\"}");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/fonts/index.ts\",\"import\":\"Alumni_Sans\",\"arguments\":[{\"variable\":\"--font-alumni_sans\",\"weight\":[\"100\",\"200\",\"300\",\"400\",\"600\",\"700\",\"800\",\"900\"],\"subsets\":[\"latin\"]}],\"variableName\":\"alumniSans\"}");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/fonts/index.ts\",\"import\":\"Anaheim\",\"arguments\":[{\"variable\":\"--font-anaheim\",\"weight\":[\"400\"],\"subsets\":[\"latin\"]}],\"variableName\":\"anaheim\"}");
import(/* webpackMode: "eager" */ "/home/cleavr/webbeukers.nl/releases/20250331030910346/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/fonts/index.ts\",\"import\":\"Roboto\",\"arguments\":[{\"variable\":\"--font-roboto\",\"weight\":[\"400\"],\"subsets\":[\"latin\"]}],\"variableName\":\"roboto\"}")